import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'
import SEO from '@/components/SEO/Seo'

const CareersPageTemplate = ({ data }) => {
  const frontmatter = data.markdownRemark.frontmatter
  const footerData = data.footer.edges[0].node.frontmatter

  return (
    <>
      <Hero
        heading={frontmatter.heading}
        fluid={frontmatter.background.childImageSharp.fluid}
      />
      <div className='flex items-center justify-center bg-white py-20 lg:py-32'>
        <div className='container sm:px-10'>
          <div className='columns'>
            <div className='columns__content lg:mr-24'>
              <div
                className='markdown-content text-black text-center lg:text-left'
                dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
              />
            </div>

            <div className='columns__content'>
              <h4 className='text-black text-center lg:text-left'>
                {frontmatter.subHeading}
              </h4>
              <div className='flex mt-8 flex-col items-center justify-between md:flex-row lg:justify-start'>
                <div className='footer__contact__item lg:mr-12'>
                  <a
                    className='icon icon--white'
                    href={`mailto:${footerData.contact.mail}`}>
                    <ReactSVG src='/img/svg/icon_mail_black.svg' />
                    <div className='sr-only'>Email Shi Hair</div>
                  </a>

                  <div className='content'>
                    <h5 className='uppercase leading-none text-black'>Email</h5>
                    <p className='text-black'>{footerData.contact.mail}</p>
                  </div>
                </div>
                <div className='footer__contact__item'>
                  <a
                    href={`tel:${footerData.contact.phone}`}
                    className='icon icon--white'>
                    <ReactSVG src='/img/svg/icon_phone_black.svg' />
                    <div className='sr-only'>Call Shi Hair</div>
                  </a>

                  <div className='content'>
                    <h5 className='uppercase leading-none text-black'>
                      Call Us
                    </h5>
                    <a
                      className='text-black'
                      href={`tel:${footerData.contact.phone}`}>
                      {footerData.contact.phone}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const CareersPage = ({ data }) => {
  const seo = data.markdownRemark.frontmatter.seo
  return (
    <Layout hideNav={true}>
      <SEO data={seo} />
      <CareersPageTemplate data={data} />
    </Layout>
  )
}

CareersPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query CareersPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "careers" } }) {
      html
      frontmatter {
        title
        heading
        subHeading
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ...SEO
      }
    }
    footer: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "footer" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            contact {
              phone
              mail
            }
          }
        }
      }
    }
  }
`

export default CareersPage
